import { RMText } from '../RMText/RMText';

import { StyledInputCharCounter } from './RMInputCharCounter.styles';

interface RMInputCharCounterProps {
  length: number;
  maxLength: number;
}

export function RMInputCharCounter({ length, maxLength }: RMInputCharCounterProps) {
  return (
    <StyledInputCharCounter>
      <RMText
        type="sans"
        size="xs"
        style={{ fontVariantNumeric: 'tabular-nums' }}
        color={length > maxLength ? 'danger' : 'on-surface-primary'}
      >
        {length > maxLength ? maxLength - length : length}/{maxLength}
      </RMText>
    </StyledInputCharCounter>
  );
}
