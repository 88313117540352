import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';
export interface PromptEditFormInput {
  question: string;
}

export interface PromptEditFormOutput {
  question: string;
}

export type PromptEditForm = Form<PromptEditFormInput, PromptEditFormOutput>;

export function createPromptEditFormSchema() {
  return z.object({
    question: z
      .string()
      .min(1, { message: 'This is a required field' })
      .max(120, { message: 'Question should contain at most 120 characters' })
      .nonempty(),
  });
}

export function createPromptEditForm(defaultValues?: DeepPartial<PromptEditFormInput>): PromptEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createPromptEditFormSchema(),
  });
}
