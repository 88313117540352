import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const PhotoContainer = styled.div`
  min-width: 2.5rem;
  margin-bottom: var(--spacing-2xl);
  position: relative;
  width: fit-content;
`;

export const Image = styled.img`
  border-radius: var(--radius-regular);
  border: 1px solid var(--border-hairline);
  max-height: 228px;
  max-width: 100%;

  &[data-mobile='true'] {
    max-height: unset;
  }
`;

export const UploadIconButton = styled(RMIconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 2px;
`;

export const StatusContainer = styled.div`
  margin-top: var(--spacing-xl);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--on-surface-tertiary);
`;
