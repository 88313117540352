import { useCallback, useMemo } from 'react';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { faClock, faPaperPlaneTop, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseEntityStatus } from '@remento/types/base-entity';
import { PromptStatus } from '@remento/types/project';
import dayjs from 'dayjs';

import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputCharCounter } from '@/components/RMInput/RMInputCharCounter';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';
import { openFilestackPicker } from '@/utils/filestack';

import { PromptEditForm } from '../../prompt-edit.form';

import { Container, Image, PhotoContainer, StatusContainer, UploadIconButton } from './PromptEdit.styles';

export interface PromptEditProps {
  form: PromptEditForm;
  photoUrl: string | null;
  readOnly?: boolean;
  disabled?: boolean;
  timeString?: string | null;
  authorFirstName?: string;
  timePeriod?: 'PRESENT' | 'PAST';
  promptStatus?: PromptStatus | BaseEntityStatus;
  sentOn?: number | null;
  onPhotoChange: (file: FilestackHandle) => void;
  onOpenPhotoPicker?: () => void;
}

export function PromptEdit({
  form,
  photoUrl,
  disabled = false,
  readOnly = false,
  authorFirstName,
  timePeriod,
  promptStatus,
  sentOn,
  onPhotoChange,
  onOpenPhotoPicker,
}: PromptEditProps) {
  const isMobile = useIsMobileViewport();
  const question = useInputValue(form, 'question');

  const timeString = useMemo(() => {
    const fromNow = dayjs(sentOn).fromNow();
    if (timePeriod === 'PRESENT') {
      return fromNow;
    } else if (promptStatus === PromptStatus.PENDING) {
      return `Sending ${fromNow}`;
    }

    return `Sent ${fromNow}`;
  }, [promptStatus, sentOn, timePeriod]);

  const messageIcon = useMemo(() => {
    if (timePeriod === 'PRESENT') {
      return faPen;
    } else if (promptStatus === PromptStatus.PENDING) {
      return faClock;
    }

    return faPaperPlaneTop;
  }, [timePeriod, promptStatus]);

  const handleOpenPhotoPicker = useCallback(async () => {
    onOpenPhotoPicker?.();

    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        exposeOriginalFile: true,
        onUploadDone: ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          onPhotoChange(filesUploaded[0]);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [onOpenPhotoPicker, onPhotoChange]);

  return (
    <Container>
      {photoUrl !== null && (
        <PhotoContainer>
          {disabled === false && (
            <UploadIconButton
              color="white"
              tooltip={{ label: 'Change photo', position: 'top' }}
              backgroundColor="dark"
              stateColor="lighten-white"
              icon={faPenToSquare}
              onClick={handleOpenPhotoPicker}
            />
          )}
          <Image src={photoUrl} data-mobile={isMobile} />
        </PhotoContainer>
      )}
      <InputContainer form={form} path="question">
        {(props) =>
          readOnly ? (
            <>
              <RMText type="sans" bold size="xs" color="on-surface-secondary">
                {photoUrl ? 'Question for this photo' : 'Your question'}
              </RMText>
              <RMSpacer direction="column" spacing="sm" />
              <RMText type="sans" size="s" color="on-surface-primary">
                {question}
              </RMText>
            </>
          ) : (
            <RMInput
              id="question"
              label={photoUrl ? 'Question for this photo' : 'Your question'}
              textarea
              fontFamily="sans"
              rows={6}
              readOnly={readOnly}
              disabled={disabled}
              {...props}
            />
          )
        }
      </InputContainer>
      {disabled === false && readOnly === false && (
        <RMInputCharCounter length={question?.length ?? 0} maxLength={120} />
      )}

      {readOnly && (
        <StatusContainer>
          {messageIcon && <FontAwesomeIcon icon={messageIcon} size="sm" />}
          <RMText type="sans" size="xxs" bold>
            {timeString} {timePeriod === 'PRESENT' && <>· {authorFirstName}</>}
          </RMText>
        </StatusContainer>
      )}
    </Container>
  );
}
