import { styled } from '@linaria/react';

export const StyledInputCharCounter = styled.div`
  display: flex;
  margin-top: var(--spacing-xs);

  & > span {
    margin-left: auto;
  }
`;
